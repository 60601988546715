var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('StatusFilterList',_vm._b({attrs:{"list":_vm.list},on:{"change":_vm.change},scopedSlots:_vm._u([{key:"card",fn:function(ref){
var dataSource = ref.dataSource;
return _c('ProCard',{attrs:{"dataSource":dataSource},nativeOn:{"click":function($event){return _vm.push(dataSource.PolicyID)}}},[_c('status-box',{attrs:{"slot":"status","status":dataSource.status,"evaluate":dataSource.evaluate,"ReviewCount":dataSource.ReviewCount},slot:"status"},[(dataSource.status === 30 && dataSource.evaluate === 0)?_c('span',[_vm._v("匹配中")]):(dataSource.status === 30 && dataSource.evaluate === 1)?_c('span',[_vm._v(" 评估中 ")]):(
            dataSource.status === 30 &&
            dataSource.evaluate === 2 &&
            !dataSource.ReviewCount
          )?_c('span',[_vm._v(" 已完成 ")]):(
            dataSource.status === 30 &&
            dataSource.evaluate === 2 &&
            dataSource.ReviewCount
          )?_c('span',[_vm._v("已评价")]):(dataSource.status === -1)?_c('span',[_vm._v("已关闭")]):_vm._e()])],1)}}]),model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}},'StatusFilterList',_vm.params,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }